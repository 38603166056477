/**
 * Show the ovelerlay
 *
 * @param {string} overlayClass - The unique class to give to the overlay
 * @param {string} zindex - Level at which to show the overlay
 *
 */
function showOverlay( overlayClass, zindex ) {
	// Create the DOM node
	let thirdpressOverlay = document.createElement( 'div' );

	// Set the classes
	thirdpressOverlay.setAttribute(
		'class',
		'thirdpress-overlay ' + overlayClass
	);

	// Set the z-index
	thirdpressOverlay.style.zIndex = zindex;

	// Display the overlay
	document.body.appendChild( thirdpressOverlay );
}

/**
 * Hide the overlay
 *
 * @param {string} overlayClass - The unique class over the overlay to hide
 *
 */
function hideOverlay( overlayClass ) {
	// Get the DOM node
	let thirdpressOverlay = document.querySelector( '.' + overlayClass );

	// Remove the overlay
	document.body.removeChild( thirdpressOverlay );
}
